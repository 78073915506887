import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="info"
          className={`${this.props.article === 'info' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Info</h2>
          <p>
            We provide an experienced and professional bartender. The client brings the alcohol, mixers, and garnishes. The client assumes liability for any alcohol related issue such as cutting off a guest and ensuring designated drivers are used. Discuss this with your venue as they will have their own policy.
          </p>
          <p>
            Please review our guide on “how to stock a bar” for information on quantity and types of alcohol to bring.
          </p>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          {close}
        </article>

        <article
          id="services"
          className={`${this.props.article === 'services' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Services</h2>
          <p>
            Standard 5 hour event
            <br />$150 for one bartender
            <br />$25 more for each additional hour
            <br />$50 for plastic cups, napkins, and ice
          </p>
          <p>
            $100 for additional bartender. Parties of 200 or more will require 2 bartenders.
            If extensive liquor & mixed drinks are being served 2 bartenders will be needed to insure timely service.
          </p>
          <p>
            Events need to be booked 2 weeks in advance.
          </p>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact Tend 2 LLC</h2>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> */}
          <p>
            Tel: <a href="tel:1-949-257-7804">1-949-257-7804</a>
            <br />
            Email: <a href="mailto:rebekahgwhitmire@gmail.com?Subject=Booking%20An%20Event?" target="_top">rebekahgwhitmire@gmail.com</a>
          </p>
          <ul className="icons">
            <li>
              <a href="https://www.facebook.com/Tend-2-LLC-104901410980846/" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
